<template>
    <div class="content-add-member">
         <!-- Primary use of this component is for going to different pages -->
        <!-- But it's emit action could theoretically allow you to utilize it for going to different sections on a single page via anchor -->
     
        <div class="add-member-tabs">
            <AddMemberTabs :pages="tabPages" @action="goToPage" />
        </div>
        <div class="add-member-tabs-content">
            <div class="section-add-new-client">
                <AddNewClient />
            </div>
        </div>
    </div>
</template>

<script>
    import AddMemberTabs from '../../components/add_member/AddMemberTabs.vue';
    import { ValidationObserver } from 'vee-validate';
    import AddNewClient from '../../components/add_member/AddNewClient.vue';

    export default {
        components: {
            AddMemberTabs,
            ValidationObserver,
            AddNewClient
        },
        name: 'AddMember',
        data() {
            return {
                tabPages: [
                    { label: 'Add New Member', name: 'AddNewMember'},
                    // { label: 'Add New User', name: 'AddNewUser' },
                    // { label: 'Create Appointment', name: 'CreateAppointment'},
                ],        
            };
        },
        methods: {
            goToPage(page) {
                this.$router.push(page);
            }
           
        }
    };
</script>