<template>
        <!-- Primary use of this component is for going to different pages -->
        <!-- But it's emit action could theoretically allow you to utilize it for going to different sections on a single page via anchor -->
        <div class="add-member-menu">
            <div v-for="(page, i) in pages" :key="i">
                <button @click="doAction(page)" :class="getClass(page.name)">
                    <div class='add-member-menu-icon'>
                        <span v-if="page.name === 'AddNewUser'" class="material-icons-outlined">manage_accounts</span>
                        <!-- <span v-if="page.name === 'AddNewMember'" class="material-icons-outlined">people_alt</span> -->
                        <svg v-if="page.name === 'AddNewMember'" width="40" height="47" viewBox="0 0 55 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M40.1161 24.4847C38.9332 24.4847 37.9651 23.5676 37.9651 22.4462C37.9651 21.3251 38.9328 20.4076 40.1161 20.4076C43.6244 20.4076 46.4608 17.7071 46.4478 14.3947C46.4342 11.0823 43.5982 8.3818 40.103 8.3942C39.5653 8.3942 39.0275 8.45774 38.5169 8.58526C37.3608 8.86562 36.1915 8.21603 35.8957 7.12034C35.5998 6.02465 36.2853 4.91653 37.4414 4.63614C43.1275 3.23481 48.9481 6.45789 50.4266 11.8594C51.9053 17.2481 48.5043 22.7643 42.8047 24.1655C41.9172 24.3699 41.0166 24.4845 40.116 24.4845L40.1161 24.4847Z" fill="white"/>
                            <path d="M20.0613 24.4848C12.9232 24.4848 7.14331 19.0068 7.14331 12.2424C7.14331 5.47796 12.9236 0 20.0613 0C27.1994 0 32.9793 5.47796 32.9793 12.2424C32.9657 19.0071 27.1855 24.4848 20.0613 24.4848ZM20.0613 4.07549C15.3027 4.07549 11.4445 7.73182 11.4445 12.2416C11.4445 16.7514 15.3027 20.4077 20.0613 20.4077C24.8199 20.4077 28.6781 16.7514 28.6781 12.2416C28.6781 7.73182 24.8199 4.07549 20.0613 4.07549Z" fill="white"/>
                            <path d="M37.563 46.2183C36.3801 46.2183 35.412 45.3012 35.412 44.1797V40.842C35.412 36.1029 31.3523 32.2684 26.3653 32.2684H13.757C8.75635 32.2684 4.71028 36.1158 4.71028 40.842V44.1797C4.71028 45.3007 3.7426 46.2183 2.55924 46.2183C1.37636 46.2183 0.408203 45.3012 0.408203 44.1797V40.842C0.421799 33.8609 6.39011 28.1924 13.7559 28.1924H26.3642C33.7306 28.2053 39.7119 33.8614 39.7119 40.842V44.1797C39.7123 45.3012 38.7447 46.2183 37.5618 46.2183H37.563Z" fill="white"/>
                            <path d="M51.892 46.2178C50.7091 46.2178 49.7409 45.3007 49.7409 44.1793V40.8415C49.7409 36.1025 45.6813 32.268 40.6943 32.268C39.5114 32.268 38.5432 31.3509 38.5432 30.2295C38.5432 29.1084 39.5109 28.1909 40.6943 28.1909C48.0606 28.2038 54.0419 33.86 54.0419 40.8405V44.1782C54.0424 45.3001 53.0742 46.2172 51.8914 46.2172L51.892 46.2178Z" fill="white"/>
                        </svg>
                        <span v-if="page.name === 'CreateAppointment'" class="material-icons-outlined">event</span>
                    </div>
                    <div :class="page.name == $route.name ? 'add-member-menu-label' : 'add-member-menu-label'">{{
                        page.label }}
                    </div>
                </button>
            </div>

        </div>
</template>

<script>
    export default {
        props: {
            pages: {
                type: Array,
                required: true
            }
        },
        name: 'AddMemberTabs',
        data() {
            return {
               
            };
        },
        methods: {
            doAction(page) {
                this.$emit('action', page);
            },
            getClass(pageName) {
                let classes = 'add-member-menu-button'
             
                if (pageName == this.$route.name) {
                    classes += ' add-member-menu-active'
                }

                return classes
            }
        }
    };
</script>
